import React, { forwardRef } from 'react'
import styled, { css } from 'styled-components'
import { MqTablet, MqDesktop } from 'streamr-ui/css/consts'

const Root = styled.div`
    && {
        ${({ $headMobile, $property }) =>
            $headMobile != null &&
            css`
                ${$property}-top: ${$headMobile};
            `}

        ${({ $tailMobile, $property }) =>
            $tailMobile != null &&
            css`
                ${$property}-bottom: ${$tailMobile};
            `}
    
        ${({ $headTablet, $tailTablet, $property }) =>
            ($headTablet != null || $tailTablet != null) &&
            css`
                @media ${MqTablet} {
                    ${$headTablet != null &&
                    css`
                        ${$property}-top: ${$headTablet};
                    `}

                    ${$tailTablet != null &&
                    css`
                        ${$property}-bottom: ${$tailTablet};
                    `}
                }
            `}
    
        ${({ $headDesktop, $tailDesktop, $property }) =>
            ($headDesktop != null || $tailDesktop != null) &&
            css`
                @media ${MqDesktop} {
                    ${$headDesktop != null &&
                    css`
                        ${$property}-top: ${$headDesktop};
                    `}

                    ${$tailDesktop != null &&
                    css`
                        ${$property}-bottom: ${$tailDesktop};
                    `}
                }
            `}
    }
`

const Spacer = forwardRef(function Spacer(
    { head: headProp = '', tail: tailProp, pad, ...props },
    ref,
) {
    const head = typeof headProp === 'string' ? headProp : ''

    // eslint-disable-next-line react/destructuring-assignment, no-nested-ternary
    const tail =
        tailProp === true && props.children != null
            ? head
            : typeof tailProp === 'string'
            ? tailProp
            : ''

    const [headMobile, headTablet, headDesktop] = head.split(/\s*[,\s]\s*/)

    const [tailMobile, tailTablet, tailDesktop] = tail.split(/\s*[,\s]\s*/)

    const property = pad ? 'padding' : 'margin'

    return (
        <Root
            {...props}
            ref={ref}
            $property={property}
            $headDesktop={headDesktop}
            $headMobile={headMobile}
            $headTablet={headTablet}
            $tailDesktop={tailDesktop}
            $tailMobile={tailMobile}
            $tailTablet={tailTablet}
        />
    )
})

export default Spacer
